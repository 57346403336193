export const Dates = [
    {day: '2022/10/01', image: 'assets/1.png'},
    {day: '2022/10/02', image: 'assets/2_2.png'},
    {day: '2022/10/03', image: 'assets/3.png'},
    {day: '2022/10/04', image: 'assets/4.png'},
    {day: '2022/10/05', image: 'assets/5_2.png'},
    {day: '2022/10/06', image: 'assets/6.png'},
    {day: '2022/10/07', image: 'assets/7.png'},
    {day: '2022/10/08', image: 'assets/8.png'},
    {day: '2022/10/09', image: 'assets/9.png'},
    {day: '2022/10/10', image: 'assets/10.png'},
    {day: '2022/10/11', image: 'assets/11_2.png'},
    {day: '2022/10/12', image: 'assets/12.png'},
    {day: '2022/10/13', image: 'assets/13.png'},
    {day: '2022/10/14', image: 'assets/14.png'},
    {day: '2022/11/15', image: 'assets/15.png'},
    {day: '2022/11/16', image: 'assets/16_2.png'},
    {day: '2022/11/17', image: 'assets/17.png'},
    {day: '2022/11/18', image: 'assets/18.png'},
    {day: '2022/11/19', image: 'assets/19.png'},
    {day: '2022/11/20', image: 'assets/20.png'},
    {day: '2022/11/21', image: 'assets/21_2.png'},
    {day: '2022/11/22', image: 'assets/22_2.png'},
    {day: '2022/11/23', image: 'assets/23.png'},
    {day: '2022/10/24', image: 'assets/24.png'},];


export const desktopDates = [
    {day: '2022/10/10', image: 'assets/10.png', rowSpan: 1, colSpan: 1},
    {day: '2022/11/16', image: 'assets/16.png', rowSpan: 1, colSpan: 2},
    {day: '2022/10/13', image: 'assets/13.png', rowSpan: 1, colSpan: 1},
    {day: '2022/11/19', image: 'assets/19.png', rowSpan: 1, colSpan: 1},
    {day: '2022/11/17', image: 'assets/17.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/12', image: 'assets/12.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/09', image: 'assets/9.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/02', image: 'assets/2.png', rowSpan: 2, colSpan: 1},
    {day: '2022/10/06', image: 'assets/6.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/05', image: 'assets/5.png', rowSpan: 1, colSpan: 2},
    {day: '2022/11/20', image: 'assets/20.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/03', image: 'assets/3.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/14', image: 'assets/14.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/04', image: 'assets/4.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/11', image: 'assets/11.png', rowSpan: 2, colSpan: 1},
    {day: '2022/11/21', image: 'assets/21.png', rowSpan: 1, colSpan: 2},
    {day: '2022/11/15', image: 'assets/15.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/08', image: 'assets/8.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/24', image: 'assets/24.png', rowSpan: 1, colSpan: 1},
    {day: '2022/11/23', image: 'assets/23.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/01', image: 'assets/1.png', rowSpan: 1, colSpan: 1},
    {day: '2022/11/22', image: 'assets/22.png', rowSpan: 1, colSpan: 2},
    {day: '2022/11/18', image: 'assets/18.png', rowSpan: 1, colSpan: 1},
    {day: '2022/10/07', image: 'assets/7.png', rowSpan: 1, colSpan: 1},
]
