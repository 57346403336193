import {axiosInstance} from "./axios";

export const getUserToken = () => axiosInstance.get('/get-user-token');

export const saveVisit = (day, edm_id) => axiosInstance.post('/visits', {
   day: day,
   userhash: JSON.parse(localStorage.getItem("user")).token,
   edm_id: edm_id
});

export const saveLike = (dayId) => axiosInstance.post('/likes', {
   day: dayId,
   userhash: JSON.parse(localStorage.getItem("user")).token
});

export const checkLiked = (day, successHandler) =>
    axiosInstance.get(`/check-like?day=${day}&userhash=${JSON.parse(localStorage.getItem("user")).token}`)
    .then((response) => {
        successHandler(response);
    })
    .catch((err) => {
       console.log(err);
    });