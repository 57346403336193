import React from 'react';

const Game1 = () => {
    return (
        <div>
            <h3>Mikulás játék</h3>
            <iframe src="HTML5/index.html" width='100%' height='600px' frameBorder="0" className={'my-5'}/>
            <h4>Segíts a Mikulásnak eljuttatni az ajándékokat a kéményekbe.</h4>
        </div>
    );
};

export default Game1;