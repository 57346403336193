import React, {useEffect, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import Card from "../../Components/Card/Card";
import classes from "../Home/Home.module.scss"
import logo from '../../assets/logo1.png'
import {getUserToken} from "../../api/requests";

const Login = () => {

        const {login} = useAuth();
        const [password, setPassword] = useState('');
        const secretPW = '2022';
        const [error, setError] = useState({text: '', isVisible: false});
        const [userToken, setUserToken] = useState(null);
        const handlePasswordChange = (e) => {
            setPassword(e.target.value)
        }

        useEffect(() => {
            getUserToken()
                .then((res) => {
                    if(res.data.token) {
                        setUserToken(res.data.token);
                    }
                })
                .catch((err) => {
                    console.log(error);
                });
            login({data: secretPW, token: userToken}); // AUTO LOGIN!
        },[]);

        const handleLogin = () => {
            if (password === secretPW) {
                /*getUserToken()
                    .then((res) => {
                        if(res.data.token) {
                            setUserToken(res.data.token)
                        }
                    })
                    .catch((err) => {
                        console.log(error);
                    });*/
                login({data: password, token: userToken})
            } else {
                setError({text: 'Ez nem a titkos jelszó!', isVisible: true});
            }

        }

        return (
            <div
                className={"flex flex-col items-center text-white justify-center bg-brandPrimary text-center h-screen  " + classes['bg-image']}>
                <Card>
                    <img src={logo} alt="" className={'max-w-[250px] mx-auto'}/>
                    <h3 className={'text-white my-3'}>Digitális Adventi Naptár Demó</h3>
                    <div className={'font-bold leading-4 text-white'}>Decemberben naponta kedveskedünk Neked
                        valamivel. <br/>
                        Készen állsz egy meglepetésre?
                    </div>
                    <div className={'italic text-white my-5'}>Demó jelszó: 2022</div>
                    <form onSubmit={handleLogin} className="flex flex-col my-5">
                        <input type="password" value={password} onChange={handlePasswordChange} id={'password'}
                               className={'rounded-lg bg-white/40 text-black px-5 border-none p-2.5 placeholder:text-slate-700 placeholder:font-bold'}
                               placeholder={'Jelszó'}/>
                    </form>

                    <button
                        className={"btn-primary h-[44px]"} onClick={handleLogin}>Bejelentkezés
                    </button>
                    {error.isVisible &&
                        <div className="text-brandSecondary pt-5 text-center">{error.text}</div>}
                </Card>

            </div>
        );
    }
;

export default Login;
